import { Tooltip, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material/styles';

export const InfoCircle = ({ infoText }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <Tooltip title={infoText} placement='top'>
      <IconButton
        size='small'
        sx={{
          color: color, // Adjust the icon color
          padding: 0, // Reduce padding for a smaller circle
          marginLeft: 1, // Add spacing to separate from text
        }}
      >
        <InfoOutlinedIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};
