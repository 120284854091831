import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import ReactGA from 'react-ga4';
import { Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import { Controller, useForm } from 'react-hook-form';
import config from '../../config';
import { UserInputSummary } from './UserInputSummary';
import { VehicleSummary } from './VehicleSummary';
import { InfoBox } from '../../components/InfoBox';
import { SubHeadline } from '../../components/SubHeadline';
import { useTheme } from '@mui/material/styles';

export const FormStepThree = ({
  formState,
  setFormState,
  sendData,
  handleBack,
  loading,
  partner,
  directSubmit,
  bankData,
  paymentRecipient,
}) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: formState });

  const formValues = watch();
  const theme = useTheme();
  const platformPartner = partner.platformPartner;
  const platformPartnerPath =
    platformPartner === 'Greenfactory' ? 'greenfactory' : 'thgqp';

  useEffect(() => {
    document.getElementById('wrapper').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, []);

  useEffect(() => {
    if (partner && partner.name === 'THG-QUOTENPORTAL') {
      ReactGA.event({
        category: 'Dataform',
        action: 'open_step3',
      });
    }
  }, [partner]);

  const onSubmit = (data) => {
    setFormState(data);
    sendData();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Top Heading */}
      <Box sx={{ mt: 1, mb: 4, paddingLeft: { xs: '8px', md: '24px' } }}>
        <Typography variant='h5' gutterBottom>
          Bitte überprüfen Sie Ihre Angaben
        </Typography>
        <Typography variant='body1' color='textSecondary'>
          Überprüfen Sie alle Daten, bevor Sie fortfahren.
        </Typography>
      </Box>

      <Box>
        <UserInputSummary
          formValues={formValues}
          bankData={bankData}
          formState={formState}
          handleBack={handleBack}
          paymentRecipient={paymentRecipient}
        />
        <VehicleSummary
          formState={formState}
          partner={partner}
          theme={theme}
          formValues={formValues}
        />
      </Box>

      {!directSubmit && (
        <Box sx={{ padding: { xs: '8px', md: '0px 24px' }, mb: 4 }}>
          <Box sx={{ mb: 2 }}>
            <InfoBox color={theme.palette.primary.main} />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Controller
              name='concession'
              control={control}
              a
              rules={{ required: true }}
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    className={errors.concession ? 'error' : ''}
                    control={<Checkbox {...field} />}
                    label='Hiermit bestätige ich, dass ich berechtigt bin, die THG-Quotenprämie für die angegebenen Fahrzeuge zu beantragen.'
                  />
                </FormGroup>
              )}
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mb: 2 }}>
            <Controller
              name='concession2'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    className={errors.concession2 ? 'error' : ''}
                    control={<Checkbox {...field} />}
                    label='Hiermit bestätige ich, dass für die angegebenen Fahrzeuge im ausgewählten Abrechnungszeitraum noch keine THG-Quotenprämien beantragt wurden.'
                  />
                </FormGroup>
              )}
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mb: 2 }}>
            <Controller
              name='privacy'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    className={errors.privacy ? 'error' : ''}
                    control={<Checkbox {...field} />}
                    label={
                      <>
                        Ja, ich bin mit den{' '}
                        <Link
                          href={`${config.WEBAPP_HOST}/${platformPartnerPath}/nutzungsbedingungen`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Nutzungsbedingungen
                        </Link>{' '}
                        einverstanden und habe die{' '}
                        <Link
                          href={`${config.WEBAPP_HOST}/${platformPartnerPath}/datenschutz`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Datenschutzerklärung
                        </Link>{' '}
                        gelesen.
                      </>
                    }
                  />
                </FormGroup>
              )}
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mb: 2 }}>
            <Controller
              name='terms'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    className={errors.terms ? 'error' : ''}
                    control={<Checkbox {...field} />}
                    label={
                      <>
                        Ja, ich stimme dem Vertragsabschluss und den damit
                        einhergehenden{' '}
                        <Link
                          href={`${config.WEBAPP_HOST}/thgqp/agb`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          AGB
                        </Link>{' '}
                        der Picsure GmbH zu.
                      </>
                    }
                  />
                </FormGroup>
              )}
            />
          </Box>
        </Box>
      )}

      {(errors.terms ||
        errors.privacy ||
        errors.concession ||
        errors.concession2) && (
        <Alert severity='error' sx={{ mb: 3 }}>
          Bitte akzeptieren Sie unsere Bedingungen, bevor Sie fortfahren.
        </Alert>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          size='large'
          variant='outlined'
          onClick={() => handleBack(false)}
          sx={{ mr: 2 }}
        >
          Zurück
        </Button>
        <Box sx={{ flex: 1 }} />
        <LoadingButton
          size='large'
          disabled={formState.vehicles.length === 0}
          onClick={handleSubmit(onSubmit)}
          endIcon={<SendIcon />}
          loading={loading}
          loadingPosition='end'
          variant='contained'
          sx={{
            color: '#FFF',
            boxShadow: 'none!important',
          }}
        >
          Daten senden
        </LoadingButton>
      </Box>
    </form>
  );
};
