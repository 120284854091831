import { Grid, Alert, Box, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { RHFUploadSingleFile } from '../../components/hook-form';
import { SubHeadline } from '../../components/SubHeadline';

export const ImageForm = ({
  errors,
  step1Errors,
  step1Submitted,
  control,
  handleDropFront,
}) => {
  return (
    <>
      {/* <Box sx={{ mb: 1, display: 'flex', flexDirection: 'column' }}>
        <SubHeadline text='Fahrzeugschein' />

        <Typography sx={{ textAlign: 'center', mb: '16px' }}>
          Übermitteln Sie eine aufgeklappte Kopie des Fahrzeugscheins
          (Zulassungsbescheinigung Teil I):
        </Typography>
      </Box> */}
      <Box
        sx={{
          mt: 4,
          mb: 2,
        }}
      >
        <Typography variant='h5' gutterBottom>
          Fahrzeugschein:
        </Typography>
        <Typography variant='body1' color='textSecondary'>
          Übermitteln Sie eine aufgeklappte Kopie des Fahrzeugscheins
          (Zulassungsbescheinigung Teil I):{' '}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              marginBottom: '20px',
            }}
            id='registrationImageFront'
          >
            <Controller
              render={() => (
                <RHFUploadSingleFile
                  className={errors.registration_image ? 'error' : ''}
                  label={'Fahrzeugschein (gesamte, aufgeklappte Vorderseite)'}
                  name='registration_image'
                  maxSize={20971520}
                  onDrop={handleDropFront}
                />
              )}
              name='registration_image'
              control={control}
            />
            {step1Submitted && step1Errors.registrationImageFront && (
              <Alert sx={{ mb: 3, marginTop: '10px' }} severity='error'>
                Bitte Vorderseite Ihres Fahrzeugscheins hochladen.
              </Alert>
            )}
          </Box>
        </Grid>
      </Grid>{' '}
    </>
  );
};
