import { Grid, Typography, Box, Paper, Divider } from '@mui/material';
import { NoPreview } from '../../components/upload';
import useMediaQuery from '@mui/material/useMediaQuery';
import { fCurrencyEuro } from '../../utils/formatNumber';
import { SubHeadline } from '../../components/SubHeadline';

export const VehicleSummary = ({ formState, partner, theme, formValues }) => {
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));
  const equalPayout = partner.dataformOptions?.equalPayout;

  const labelMapping = {
    M1: 'E-PKW',
    LXe: 'E-Zweirad',
    N1: 'E-Nutzfahrzeug',
    N2: 'E-Nutzfahrzeug',
    N3: 'E-Nutzfahrzeug',
    M3: 'E-Bus',
  };

  const subLabelMapping = {
    M1: 'M1',
    LXe: 'L3e, L4e, L5e, L7e',
    N1: 'N1 bis 3,5t',
    N2: 'N2 ab 3,5t bis 12t',
    N3: 'N3 ab 12t',
    M3: 'M3',
  };

  const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  if (formState.vehicles.length === 0) return null;
  const multipleVehicles = formState.vehicles.length > 1;
  return (
    <Box sx={{ padding: { sx: '8px', md: '0px 24px 24px 24px' } }}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        {multipleVehicles ? 'Fahrzeuge' : 'Fahrzeug'}
      </Typography>
      {formState.vehicles.map((vehicle, index) => {
        const fields = [
          {
            label: 'Fahrzeugklasse:',
            value: `${labelMapping[vehicle.vehicleClass]} (${
              subLabelMapping[vehicle.vehicleClass]
            })`,
          },
          {
            label: 'Abrechnungszeitraum:',
            value: vehicle.submissionYears.join(', '),
          },
          {
            label: 'THG-Quotenprämie',
            value: equalPayout
              ? fCurrencyEuro(vehicle.vehiclePayoutPrice) + ' (inkl. MwSt.)'
              : fCurrencyEuro(vehicle.vehiclePayoutPrice),
          },

          {
            label: 'Auszahlungstyp:',
            value:
              vehicle.payoutMode === 'instant'
                ? 'Sofortauszahlung'
                : 'Nach Bestätigung durch Umweltbundesamt',
          },
          ...(vehicle.charityAmount
            ? [
                {
                  label: 'Auszahlungsbetrag:',
                  value: fCurrencyEuro(
                    vehicle.vehiclePayoutPrice - vehicle.charityAmount
                  ),
                },
                {
                  label: 'Spendenbetrag:',
                  value: fCurrencyEuro(vehicle.charityAmount),
                },
              ]
            : []),
          ...(vehicle.selectedCharity &&
          vehicle.selectedCharity !== 'Keine Spende'
            ? [
                {
                  label: 'Spendenpartner:',
                  value: vehicle.selectedCharity,
                },
              ]
            : []),
        ];

        return (
          <Box key={index} sx={{ mb: 3, mt: 0, pt: 0 }}>
            <Grid container spacing={2} alignItems='flex-start'>
              {/* Left Column: Data */}
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  {fields.map((field, i) => (
                    <Grid item xs={12} sm={6} key={i}>
                      <Box sx={{ py: 1 }}>
                        <Typography variant='body2' color='textSecondary'>
                          {field.label}
                        </Typography>
                        <Typography variant='body2' sx={{ fontWeight: 600 }}>
                          {field.value}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {/* Right Column: Image */}
              <Grid item xs={12} md={6}>
                {vehicle.registration_image && (
                  <Box sx={{ mt: { xs: 2, md: 2 }, textAlign: 'center' }}>
                    {['heic', 'pdf'].includes(
                      getFileExtension(vehicle.registration_image.name)
                    ) ? (
                      <NoPreview fileName={vehicle.registration_image.name} />
                    ) : (
                      <img
                        src={URL.createObjectURL(vehicle.registration_image)}
                        alt='Fahrzeugdokument'
                        style={{
                          width: '100%',
                          maxWidth: '300px',
                          objectFit: 'contain',
                          maxHeight: '300px',
                        }}
                      />
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        );
      })}
      <Divider sx={{ my: 1 }} />
    </Box>
  );
};
