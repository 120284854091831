import { Typography, Box, TextField, Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';
import { InfoCircle } from '../../components/InfoCircle';
import CheckIcon from '@mui/icons-material/Check';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { SubHeadline } from '../../components/SubHeadline';
import { isValidIBAN } from 'ibantools-germany';
import { useEffect } from 'preact/hooks';

export const BankDataForm = ({
  errors,
  dirtyFields,
  formValues,
  control,
  touchedFields,
  successFields,
  errorFields,
  handleIbanChange,
  bankData,
  firstNameValue,
  lastNameValue,
  typeValue,
  companyNameValue,
  setValue,
  setFormState,
  formState,
  paymentRecipient,
  setPaymentRecipient,
}) => {
  const handlePaymentRecipient = (value) => {
    setPaymentRecipient(value);
  };

  useEffect(() => {
    const paymentRecipient =
      typeValue === 'b2c'
        ? `${firstNameValue || ''} ${lastNameValue || ''}`.trim()
        : companyNameValue || '';

    if ((firstNameValue && lastNameValue) || companyNameValue) {
      setValue('paymentRecipient', paymentRecipient, { shouldDirty: true });
      handlePaymentRecipient(paymentRecipient);
    }
  }, [firstNameValue, lastNameValue, companyNameValue, typeValue, setValue]);

  return (
    <>
      {/* <Box sx={{ mb: 1, mt: 2, display: 'flex', flexDirection: 'column' }}>
        <SubHeadline text='Bankdaten' />
        <Typography sx={{ textAlign: 'center', mb: 2 }}>
          Ihre Bankdaten für die Auszahlung:
        </Typography>
      </Box> */}

      <Box
        sx={{
          mt: 4,
          mb: 2,
        }}
      >
        <Typography variant='h5' gutterBottom>
          Bankdaten:
        </Typography>
        <Typography variant='body1' color='textSecondary'>
          Ihre Bankdaten für die Auszahlung:
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {/* Kontoinhaber */}
        <Grid item xs={12} md={6}>
          <TextField
            label='Kontoinhaber'
            variant='outlined'
            fullWidth
            value={paymentRecipient}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            InputProps={{
              endAdornment: (
                <InfoCircle infoText='Der Kontoinhaber wird automatisch aus Ihren Eingaben ermittelt.' />
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Controller
              render={({ field }) => (
                <TextField
                  InputProps={{
                    endAdornment: (
                      <>
                        {errors &&
                          (Object.keys(touchedFields).includes('iban') ||
                            successFields.includes('iban') ||
                            errorFields.includes('iban')) &&
                          formValues.iban && (
                            <>
                              <InputAdornment
                                sx={{ marginRight: '20px' }}
                                position='end'
                              >
                                {errors.iban ? (
                                  <ErrorIcon color='error' />
                                ) : (
                                  <CheckIcon color='success' />
                                )}
                              </InputAdornment>
                            </>
                          )}
                      </>
                    ),
                  }}
                  {...field}
                  onChange={handleIbanChange}
                  label={'IBAN'}
                  variant='outlined'
                  fullWidth
                  error={!!errors.iban}
                />
              )}
              rules={{
                validate: {
                  required: (value) => {
                    if (!value) {
                      return false;
                    }
                    const text = value.replace(/\s/g, '');

                    return isValidIBAN(text, true);
                  },
                },
                required: true,
              }}
              name='iban'
              control={control}
              placeholder='IBAN'
            />

            <Box
              sx={{
                padding: '10px',
                paddingTop: '0px',
                marginBottom: '20px',
                fontSize: '12px',
                marginTop: '-15px',
                visibility:
                  bankData && bankData.bic && !errors.iban
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <Box
                sx={{
                  margin: '-10px',
                  mt: 1,
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  paddingLeft: '14px',
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    <strong></strong>Bank: {bankData.name}
                  </Typography>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    BIC: {bankData.bic}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
