import { Grid, Box, Typography, Divider, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const UserInputSummary = ({
  formValues,
  bankData,
  formState,
  handleBack,
  paymentRecipient,
}) => {
  const formatIban = (iban) =>
    iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ');

  const handleEdit = () => {
    handleBack(false);
  };

  return (
    <Box sx={{ padding: { xs: '8px', md: '24px 24px 0px 24px' } }}>
      {/* Kontaktdaten Section */}
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Kontaktdaten
        </Typography>
        <IconButton onClick={handleEdit} size='small'>
          <EditIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        {/* B2B*/}

        {formState.type === 'b2b' ? (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant='body2' color='textSecondary'>
                Firmenname:
              </Typography>
              <Typography variant='body2' sx={{ fontWeight: 600 }}>
                {formValues.companyName}
              </Typography>
            </Grid>
            {formValues.taxId ? (
              <>
                {' '}
                <Grid item xs={12} sm={6}>
                  <Typography variant='body2' color='textSecondary'>
                    Steuer-ID:
                  </Typography>
                  <Typography variant='body2' sx={{ fontWeight: 600 }}>
                    {formValues.taxId}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                {' '}
                <Grid item xs={12} sm={6}></Grid>
              </>
            )}
          </>
        ) : (
          <></>
        )}

        {/* First Name and Last Name */}
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            Vorname:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {formValues.firstName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            Nachname:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {formValues.lastName}
          </Typography>
        </Grid>

        {/* Street and City */}
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            Strasse:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {formValues.street} {formValues.housenumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            Stadt:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {formValues.zip} {formValues.city}
          </Typography>
        </Grid>

        {/* Email and Phone */}
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            E-Mail-Adresse:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {formValues.email}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            Telefon:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {formValues.phone}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />

      {/* Bankdaten Section */}
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ mt: 2 }}
      >
        <Typography variant='h6' sx={{ mb: 2 }}>
          Bankdaten
        </Typography>
        <IconButton onClick={handleEdit} size='small'>
          <EditIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        {/* Bank Data Rows */}
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            IBAN:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {formatIban(formValues.iban)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            BIC:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {bankData.bic}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            Kontoinhaber:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {paymentRecipient}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='body2' color='textSecondary'>
            Bankname:
          </Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>
            {bankData.name}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />
    </Box>
  );
};
