import { Box, Typography, Button, Paper } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import TimelineDot from '@mui/lab/TimelineDot';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import FindInPageIcon from '@mui/icons-material/FindInPage';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useTheme } from '@mui/material/styles';

export const SuccessSubmit = ({
  partner,
  instantPayout,
  clientEmail,
  directSubmit,
}) => {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  return (
    <Box
      sx={{
        maxWidth: '800px',
        margin: 'auto',
        padding: { xs: '0px', md: '24px' },
      }}
    >
      {/* Success Message */}
      <Box
        sx={{
          backgroundColor: '#f0fdf4',
          padding: '20px',
          borderRadius: '10px',
          textAlign: 'center',
          mb: 3,
        }}
      >
        {/* <CheckCircleOutlineIcon sx={{ color: '#22c55e', fontSize: 50 }} /> */}
        <ForwardToInboxIcon sx={{ color: '#22c55e', fontSize: 50 }} />

        <Typography sx={{ marginTop: '10px', fontWeight: '700' }}>
          {directSubmit ? (
            <>
              <Typography sx={{ marginTop: '10px' }}>
                Vielen Dank für die Übermittlung der Daten.
              </Typography>

              <span>
                Der Empfänger {clientEmail} erhält nun eine E-Mail, aus der er
                die Vermarktung der THG-Quote starten kann.
              </span>
            </>
          ) : (
            <>
              <Typography sx={{ marginTop: '10px', fontWeight: 700 }}>
                Fast geschafft!
                <br />
                Bitte bestätigen Sie Ihrer E-Mail Adresse ({`${clientEmail}`})
                innerhalb von 12 Stunden, um die Vermarktung zu starten!
              </Typography>

              <span>
                <Typography sx={{ marginTop: '10px' }}>
                  <Box sx={{ marginTop: '10px' }}>
                    <strong>Hinweis:</strong> Sollten Sie keine Nachricht
                    erhalten haben, überprüfen Sie bitte Ihren SPAM-Ordner.
                  </Box>
                </Typography>
              </span>
            </>
          )}
        </Typography>
      </Box>
      <Paper
        variant='outlined'
        sx={{
          mt: 3,
          borderRadius: '10px',
          p: 2,
        }}
      >
        {/* Timeline */}
        <Typography variant='h6' sx={{ fontWeight: 700, mb: 2 }}>
          Wie geht es weiter?
        </Typography>
        <Timeline
          position='right'
          sx={{
            width: '100%',
            p: 0,
            m: 0,
            '& .MuiTimelineItem-root:before': {
              flex: 0,
              padding: { xs: '0px', md: '6px 16px' },
            },
          }}
        >
          {/* Step 1 */}
          <TimelineItem sx={{ width: '100%' }} position='alternate'>
            <TimelineSeparator>
              <TimelineDot color='primary'>
                <FileUploadIcon sx={{ color: '#FFFFFF!important' }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                flex: 1,
                marginLeft: 0,
                paddingLeft: '20px',
                paddingRight: '20px',
                width: '100%',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                Übermittlung der Daten
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                Wir haben Ihre Daten erhalten.
              </Typography>
            </TimelineContent>
          </TimelineItem>

          {/* Step 2 */}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <CheckCircleIcon sx={{ color: '#FFFFFF!important' }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography sx={{ fontWeight: 600 }}>
                Bestätigung Ihrer Email-Adresse
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                Hierzu wurde Ihnen eine Nachricht mit einem Bestätigungslink an
                das Postfach <strong>{` ${clientEmail} `}</strong>geschickt.
                Bitte Bestätigen Sie Ihren Auftrag innerhalb von 12 Stunden.
              </Typography>
            </TimelineContent>
          </TimelineItem>

          {/* Step 3 */}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <FindInPageIcon sx={{ color: '#FFFFFF!important' }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography sx={{ fontWeight: 600 }}>
                Prüfung Ihrer Daten{' '}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                Ihre Daten werden von einem unserer Mitarbeitern überprüft.
              </Typography>
            </TimelineContent>
          </TimelineItem>

          {instantPayout ? (
            <>
              {' '}
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <EuroSymbolIcon sx={{ color: '#FFFFFF!important' }} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography sx={{ fontWeight: 600 }}>
                    Auszahlung Ihrer THG-Prämie
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    Sie erhalten Ihre THG-Prämie als Vorauszahlung.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </>
          ) : (
            <></>
          )}
          {/* Step 3 */}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <ForwardToInboxIcon sx={{ color: '#FFFFFF!important' }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography sx={{ fontWeight: 600 }}>
                Übermittlung an das Umweltbundesamt
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                Ihre Daten wurden von unserem System an das Umweltbundeamt (UBA)
                übertragen.
              </Typography>
            </TimelineContent>
          </TimelineItem>

          {/* Step 4 */}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <VerifiedIcon sx={{ color: '#FFFFFF!important' }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography sx={{ fontWeight: 600 }}>
                Bestätigung durch das Umweltbundesamt
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                Ihr Fahrzeugschein wird vom Umweltbundesamt überprüft und nach
                erfolgreicher Prüfung zertifiziert.
              </Typography>
            </TimelineContent>
          </TimelineItem>
          {!instantPayout ? (
            <>
              {' '}
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <EuroSymbolIcon sx={{ color: '#FFFFFF!important' }} />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Typography sx={{ fontWeight: 600 }}>
                    Auszahlung der THG-Prämie
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    Das Umweltbundesamt hat ihrer Daten erfolgreich zertifiert.
                    Wir werden Ihre Prämie umgehend ausgezahlt.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </>
          ) : (
            <></>
          )}
        </Timeline>
        {/* Contact Section */}
      </Paper>
      {partner && partner.dataformOptions.showHelp ? (
        <Paper
          variant='outlined'
          sx={{
            mt: 3,
            borderRadius: '10px',
          }}
        >
          <Box sx={{ padding: 2 }}>
            <Typography variant='h6' sx={{ fontWeight: 700, mb: 2 }}>
              Wir sind für Sie da
            </Typography>

            <Typography sx={{ p: 1 }}>
              Für Fragen stehen wir Ihnen von Montag bis Freitag von{' '}
              <b>08:00 - 18:00 Uhr</b> zur Verfügung. <b>Rufen Sie uns an</b>{' '}
              oder senden Sie eine <b>E-Mail</b>.
            </Typography>
            <Box
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: {
                  xs: 'column', // Stacks vertically on small screens
                  sm: 'row', // Aligns horizontally on medium and larger screens
                },
                justifyContent: {
                  sm: 'center', // Adds space between items on larger screens
                },
                alignItems: 'center',
                gap: {
                  xs: 2, // Adds spacing between items on small screens
                  sm: 0, // No gap needed on larger screens as justifyContent takes over
                },
              }}
            >
              {/* Email */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0px 16px',
                }}
              >
                <EmailIcon
                  sx={{ color: PRIMARY_MAIN, fontSize: '20px', mr: 1 }}
                />
                <a
                  href={`mailto:${partner.email}`}
                  style={{
                    color: `${PRIMARY_MAIN}`,
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                >
                  {partner.email}
                </a>
              </Box>

              {/* Phone */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0px 16px',
                }}
              >
                <PhoneIcon
                  sx={{ color: PRIMARY_MAIN, fontSize: '20px', mr: 1 }}
                />
                <a
                  href={`tel:${partner.phone}`}
                  style={{
                    color: `${PRIMARY_MAIN}`,
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                >
                  {partner.phone}
                </a>
              </Box>
            </Box>
          </Box>
        </Paper>
      ) : (
        <></>
      )}

      {/* Navigation Button */}
      {/* <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
          variant='contained'
          color='primary'
          href='/'
          sx={{ textTransform: 'none' }}
        >
          Zur Startseite
        </Button>
      </Box> */}
    </Box>
  );
};
